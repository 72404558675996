import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "view-width" }

import { ref } from "vue";
import DesktopNav from "@/components/navigation/desktopNav.vue";
import MobileNav from "./components/navigation/mobileNav.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const showDropdown = ref();

function hideNavDropdown() {
  showDropdown.value.showDropdown = false;
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: hideNavDropdown,
    class: "app-container"
  }, [
    _createVNode(DesktopNav, {
      ref_key: "showDropdown",
      ref: showDropdown,
      class: "desktop-nav"
    }, null, 512),
    _createVNode(MobileNav, { class: "mobile-nav" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ]))
}
}

})