import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dropdown-container" }

import { NavDropdownLink } from "@/types/navDropdownLink";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'navDropdown',
  props: {
  navDropdownProps: {
    type: Array as PropType<NavDropdownLink[]>,
    required: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.navDropdownProps, (navItem) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        key: navItem.route,
        to: navItem.route,
        class: "dropdown-item"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(navItem.text), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}
}

})