<template>
  <div @click="hideNavDropdown" class="app-container">
    <DesktopNav ref="showDropdown" class="desktop-nav" />
    <MobileNav class="mobile-nav" />
    <div class="view-width">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import DesktopNav from "@/components/navigation/desktopNav.vue";
import MobileNav from "./components/navigation/mobileNav.vue";

const showDropdown = ref();

function hideNavDropdown() {
  showDropdown.value.showDropdown = false;
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

.desktop-nav,
.mobile-nav {
  top: 0;
  position: sticky;
  z-index: 5;
}

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

.app-container {
  min-height: 100vh;
  min-width: 100vw;
}

.view-width {
  /* width: 66%; */
  max-width: 1320px;
  margin: 0 auto;
}

svg {
  height: 1rem;
  transition: linear 250ms;
}

svg.active {
  transform: rotate(180deg);
}

.mobile-nav {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px-768px) */
@media only screen and (max-width: 768px) {
}

/* Medium devices (landscape tablets, 768px-992px) */
@media only screen and (max-width: 992px) {
}

/* Large devices (laptops/desktops, 992px-1200px) */
@media only screen and (max-width: 1200px) {
  .view-width {
    max-width: 100%;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }
}
</style>
