import { FaqEntry } from "@/types/faqEntry";

export const FAQ_ENTRIES = [
  {
    label: "What is a transfer fee?",
    content:
      "Adagio units are managed by various rental agencies at individual owner discretion. A transfer fee is paid with each rental booking to register that stay with the association and transfer the owner's right of use to the guest during the dates booked.  Such fees are defined in Florida statute and Adagio's bylaws.",
  },
  {
    label: "Who pays the transfer fee?",
    content:
      "The fee applies to every rental stay, regardless of length of booking.  It is up to the owner or property manager of each unit how they handle the billing.  Some owners or property managers may pay this fee directly on behalf of the guest, while others may direct each guest to this site to pay the fee themselves.",
  },
  {
    label: "When is the transfer fee due?",
    content:
      "The fee must be paid prior to arrival.  If unpaid, guests will not be allowed on-site or issued parking passes for their stay.",
  },
  {
    label: "How does this help Adagio?",
    content:
      "This establishes the point of contact for each guest stay, documents who is in each unit, and helps Adagio better control who has access to the facility.  It also helps defray administrative costs and rental impact to our operations. ",
  },
  {
    label: "When does this go into effect?",
    content:
      "Bookings placed on or after Jan 1, 2025 will be subject to the transfer fee.  Bookings for 2025 that were placed prior to Jan 1, 2025 will not pay the fee but will still be required to register their stay through this site.",
  },
  {
    label: "Is the fee refundable if I cancel my reservation?",
    content:
      "Yes.  The fee can be refunded if you cancel your Adagio booking.  You will need to email info@adagioregistration.com to request a refund.  Please include evidence of your approved cancellation from your rental agency in the email.",
  },
  {
    label: "How will my personal information be used?",
    content:
      "Information gathered as part of the transfer registration will only be used internally by Adagio.  Third parties will not have access to your information.  Credit or Debit payment information is not retained once your transaction is processed.",
  },
] as FaqEntry[];
