<template>
  <div class="mobile-nav">
    <nav>
      <div class="nav-container">
        <router-link to="/" @click="closeMenu">
          <img
            class="logo"
            src="../../assets/adagioLogo.svg"
            alt="Adagio Logo"
          />
        </router-link>
        <div class="nav-container__hamburger-menu" @click="toggleMenu">
          <button class="nav-container__button">
            <img src="../../assets/burger-icon.svg" alt="Adagio Logo" />
          </button>
        </div>
      </div>

      <div class="nav-group" :class="{ 'nav-group--hidden': !showMenu }">
        <div @click="toggleMenu">
          <router-link class="menu-item" to="/">Home</router-link>
        </div>
        <InfoAccordion
          ref="isOpen"
          class="accordion"
          :class="{ active: isActive }"
          label="Register Rental Guest(s)"
          @click="toggleIsActive"
        >
          <div class="vertical">
            <div @click="toggleMenu">
              <router-link class="menu-item" to="/registration-by-renter"
                >Registration by renter</router-link
              >
            </div>
            <div @click="toggleMenu">
              <router-link class="menu-item" to="/registration-by-owner"
                >Registration by owner</router-link
              >
            </div>
            <div @click="toggleMenu">
              <router-link class="menu-item" to="/register-guests-as-pm"
                >Register guests as PM</router-link
              >
            </div>
          </div>
        </InfoAccordion>
        <div @click="toggleMenu">
          <router-link to="/adagio-rules" class="menu-item"
            >Adagio Rules</router-link
          >
        </div>
        <div @click="toggleMenu">
          <router-link to="/extra-vehicle-parking" class="menu-item">
            Extra Vehicle Parking
          </router-link>
        </div>
        <div @click="toggleMenu">
          <router-link to="/faq" class="menu-item"> FAQ </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InfoAccordion from "../infoAccordion.vue";
const showMenu = ref(false);
const isActive = ref(false);
const isOpen = ref();

function toggleMenu(): void {
  showMenu.value = !showMenu.value;
  isOpen.value.isOpen = false;
  isActive.value = false;
}

function toggleIsActive(): void {
  isActive.value = !isActive.value;
}

function closeMenu(): void {
  showMenu.value = false;
  isOpen.value.isOpen = false;
  isActive.value = false;
}
</script>

<style scoped>
.nav-container__button {
  background-color: white;

  &:hover {
    background-color: white;
  }
}

.mobile-nav {
  padding-top: 10rem;
}

nav {
  position: absolute;
  top: 0;
  width: 100%;
  padding-block: 16px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 50;
}

.nav-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
}

.nav-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  transition: height 0.6s ease;
}

.nav-group--hidden {
  height: 0vh;
  transition: height 0.6s ease;
}

.menu-item {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 1.25rem 0;
  border-bottom: 1px solid #ccc;
  /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); */

  &:hover {
    background-color: rgb(233, 233, 233);
  }
}

.accordion {
  min-height: 3.75rem;
  &:not(.active):hover {
    background-color: rgb(233, 233, 233);
  }
}

.accordion:deep(hr) {
  display: none;
}

.accordion:deep(svg) {
  z-index: unset;
}

.accordion:deep(.info-accordion__text--visible) {
  max-height: 15rem;
}

.accordion:deep(.info-accordion__label-container) {
  justify-content: center;
}

.accordion:deep(.info-accordion__button) {
  color: rgb(0, 117, 193);
  border-bottom: 1px solid #ccc;
  padding: 1.25rem;
}

.accordion:deep(.active) {
  background-color: rgb(210, 210, 210) !important;
}

.vertical {
  display: flex;
  flex-direction: column;
}

nav img {
  height: 3rem;
  width: auto;
}

.logo {
  height: 4.375rem;
}

nav a {
  /* margin: 0 1rem; */
  padding: 0 1.125rem;
  font-size: 1.25rem;
  color: rgb(0, 117, 193);
  text-decoration: none;
}

nav a:hover {
  color: rgb(0, 73, 124);
}

nav a.router-link-exact-active {
  color: rgb(27, 27, 27);
  text-decoration: underline solid rgb(27, 27, 27) 2px;
  text-underline-offset: 10px;
}

.register-dropdown-toggle {
  cursor: pointer;
  position: relative;
}
.nav-dropdown-container {
  display: inline-block;
}

.nav-dropdown-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nav-dropdown {
  position: absolute;
  transform: translate(-1rem, 2rem);
  box-sizing: inherit;
}

.nav-dropdown > a {
  color: rgb(94, 94, 94);
}

.nav-dropdown > a.router-link-exact-active {
  color: rgb(94, 94, 94);
  text-decoration: none;
  font-weight: 700;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px-768px) */
@media only screen and (max-width: 768px) {
}

/* Medium devices (landscape tablets, 768px-992px) */
@media only screen and (max-width: 992px) {
}

/* Large devices (laptops/desktops, 992px-1200px) */
@media only screen and (max-width: 1200px) {
}
</style>
