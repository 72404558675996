<template>
  <div class="info-accordion">
    <button
      class="info-accordion__button"
      :class="{ active: isOpen }"
      @click="toggleAccordion"
    >
      <div class="info-accordion__label-container">
        <span class="info-accordion__label">{{ label }}</span>
        <ChevronDown :class="{ active: isOpen }" />
      </div>
    </button>
    <div
      class="info-accordion__text"
      :class="{ 'info-accordion__text--visible': isOpen }"
      @click.prevent="toggleAccordion"
    >
      <slot></slot>
    </div>
    <hr />
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineExpose, ref } from "vue";
import ChevronDown from "./chevronDown.vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
});

const isOpen = ref(false);
defineExpose({ isOpen });

function toggleAccordion(): void {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped>
.info-accordion {
  margin: 0 5rem;
}

svg {
  z-index: -1;
}

.active {
  color: rgb(0, 117, 193) !important;
}

.info-accordion__button {
  width: 100%;
  text-align: left;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(94, 94, 94);
}

.info-accordion__button:hover {
  color: rgb(0, 117, 193);
  background-color: rgba(0, 0, 0, 0) !important;
}

.info-accordion__text {
  margin: 0 2rem;
  text-align: left;
  overflow: hidden;
  max-height: 0rem;
  transition: max-height 0.4s ease;
}

.info-accordion__text--visible {
  max-height: 8rem;
  transition: max-height 0.4s ease;
}

@media only screen and (max-width: 1200px) {
  .info-accordion {
    margin: 0 3rem;
  }

  .info-accordion__text--visible {
    max-height: 16rem;
    transition: max-height 0.4s ease;
  }
}

@media only screen and (max-width: 900px) {
  .info-accordion {
    margin: 0 1rem;
  }
}

@media only screen and (max-width: 700px) {
  .info-accordion {
    margin: 0;
  }

  .info-accordion__text--visible {
    max-height: 24rem;
    transition: max-height 0.4s ease;
  }
}

.info-accordion__label-container {
  display: flex;
  justify-content: space-between;
}
</style>
