<template>
  <div class="pm-registration">
    <div class="loading-overlay" v-if="uploadPending">
      <div class="loading-overlay__spinner"></div>
      <p class="loading-overlay__text">Uploading file... Please wait</p>
    </div>
    <h3 class="registration-header">
      <div class="registration-header__divider-line"></div>
      Registration by PM
      <div class="registration-header__divider-line"></div>
    </h3>

    <div class="pm-registration__content">
      <div class="pm-registration-box">
        <p>Download bulk registration form (xlsx)</p>
        <button type="submit" @click="downloadExcelFileToClient">
          Download
        </button>
      </div>

      <div class="pm-registration-box">
        <p>Upload bulk registration CSV</p>
        <label class="pretends-to-be-button">
          Upload
          <input
            type="file"
            accept=".csv"
            style="display: none"
            @change="handleFileUpload"
          />
        </label>
      </div>
    </div>
    <p v-if="peopleObjects.length !== 0" class="filename">
      Registrations from "{{ fileName }}":
    </p>
    <div class="document-container">
      <div
        v-for="(document, index) in peopleObjects"
        :key="index"
        class="document-cards"
      >
        <DocumentCard
          v-if="!isPending"
          :valid="peopleObjectsValidationRef[index]"
          :document="document"
          @update-document="
            (newDoc) => {
              updateDocument(newDoc, index);
            }
          "
        />
      </div>
    </div>

    <div class="error-text" v-if="!isValid()">
      Submission was invalid. Please find the highlighted registration(s) and
      correct them.
    </div>

    <button
      v-if="peopleObjects.length !== 0"
      class="submit-all"
      @click="handleSubmit(peopleObjects)"
      :disabled="isPending"
    >
      <span v-if="!isPending">Submit</span>
      <div class="spinner" v-else>
        <div class="form-spinner-circle"></div>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as Papa from "papaparse";
import {
  stringToDate,
  getNextSaturday,
  dateToYyyyMmDdString,
} from "@/helpers/dateHelpers";
import DocumentCard from "@/components/documentCard.vue";
import { toast, type ToastOptions } from "vue3-toastify";

const peopleObjects = ref(Array<any>());
const peopleObjectsValidationRef = ref(Array<boolean>());
const fileName = ref("");
const uploadPending = ref(false);
const isPending = ref(false);

const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);
const PHONE_REGEX = new RegExp("^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$");

async function handleSubmit(people: any[]) {
  validateAll();
  if (isValid()) {
    isPending.value = true;
    people.forEach((person) => {
      person.ArrivalDate = dateToYyyyMmDdString(person.ArrivalDate);
      person.DepartureDate = dateToYyyyMmDdString(person.DepartureDate);
    });
    try {
      const res = await fetch(
        "https://app-y4uhxvobbq-uc.a.run.app/property-manager-checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            docArray: people,
          }),
        }
      );
      const data = await res.json();
      window.location.href = data.checkoutSessionUrl;
    } catch (error) {
      console.error(error);

      toast("Something went wrong... please try again", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      } as ToastOptions);

      isPending.value = false;
    }
  }
}

function getDayDiff(submittedDocument: any): number {
  let Difference_In_Time =
    submittedDocument.DepartureDate.getTime() -
    submittedDocument.ArrivalDate.getTime();

  // Calculating the no. of days between
  // two dates
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  return Difference_In_Days;
}

/**
 * Initiates a download of the Excel template file.
 */
function downloadExcelFileToClient() {
  const filename = "bulk-upload-template.xlsx";
  const pseudoAnchorTag = document.createElement("a");
  pseudoAnchorTag.href = filename;
  pseudoAnchorTag.download = filename;

  pseudoAnchorTag.click();
  pseudoAnchorTag.remove();
}

/**
 * Validates the uploaded CSV file or berates the user severely if it couldn't be validated.
 */
async function handleFileUpload(event: Event): Promise<void> {
  const target = event.target as HTMLInputElement;
  if (!target || !target.files) return;

  uploadPending.value = true;
  const uploadedFile = target.files[0];

  let testText = await uploadedFile.text();
  fileName.value = uploadedFile.name;

  Papa.parse(testText, {
    complete: function (result) {
      let people = result.data as string[][];
      people.splice(0, 4);
      people.forEach((person) => {
        let entryCounter = 0;
        person.splice(0, 1);
        person.forEach((entry) => {
          if (entry.length !== 0) entryCounter += 1;
        });

        if (entryCounter !== 0) {
          const personData = {
            Name: person[0],
            Email: person[1],
            GuestPhone: parsePhoneNum(person[2]),
            AltPhone: parsePhoneNum(person[3]),
            Unit: person[4],
            ArrivalDate: parseUploadDate(person[5]),
            DepartureDate: parseUploadDate(person[6]),
            TotalAdults: person[7].toString(),
            TotalChildren: person[8].toString(),
            RegistrationDate: new Date(),
            Acknowledgement: true,
            DurationOfStay:
              getDayDiff({
                ArrivalDate: parseUploadDate(person[5]),
                DepartureDate: parseUploadDate(person[6]),
              }) + 1,
          };
          peopleObjects.value.push(personData);
          peopleObjectsValidationRef.value.push(true);
        }
      });
    },
  });
  uploadPending.value = false;
}

function validateAll(): void {
  let nameIsValid,
    emailIsValid,
    guestPhoneIsValid,
    altPhoneIsValid,
    unitIsValid,
    datesValid,
    numAdultsIsValid,
    numChildrenIsValid;

  peopleObjects.value.forEach((person, index) => {
    nameIsValid = validateFilled(person.Name);
    emailIsValid = validateAgainstRegex(person.Email, EMAIL_REGEX);
    guestPhoneIsValid = validateAgainstRegex(person.GuestPhone, PHONE_REGEX);
    unitIsValid = validateFilled(person.Unit);
    datesValid = validateDates(person);
    numAdultsIsValid =
      validateFilled(person.TotalAdults) && person.TotalAdults > 0;
    numChildrenIsValid = validateFilled(person.TotalChildren);

    if (validateFilled(person.AltPhone))
      altPhoneIsValid = validateAgainstRegex(person.AltPhone, PHONE_REGEX);
    else altPhoneIsValid = true;

    if (
      nameIsValid &&
      emailIsValid &&
      guestPhoneIsValid &&
      altPhoneIsValid &&
      unitIsValid &&
      datesValid &&
      numAdultsIsValid &&
      numChildrenIsValid
    )
      peopleObjectsValidationRef.value[index] = true;
    else peopleObjectsValidationRef.value[index] = false;
  });
}

function validateFilled(info: string): boolean {
  return info.length !== 0;
}

function validateAgainstRegex(info: string, regex: RegExp): boolean {
  return regex.test(info) && validateFilled(info);
}

function validateDates(info: any): boolean {
  let departureDate = info.DepartureDate;
  let arrivalDate = info.ArrivalDate;

  if (!isValidDate(departureDate) || !isValidDate(arrivalDate)) return false;

  if (
    departureDate < stringToDate(dateToYyyyMmDdString(new Date())) ||
    arrivalDate < stringToDate(dateToYyyyMmDdString(new Date()))
  )
    return false;

  if (arrivalDate >= departureDate) return false;

  return validateFilled(arrivalDate) && validateFilled(departureDate);
}

function isValidDate(d: Date) {
  return !isNaN(d.getTime());
}

function updateDocument(newDoc: any, index: number): void {
  peopleObjects.value[index].Name = newDoc[0];
  peopleObjects.value[index].Email = newDoc[1];
  peopleObjects.value[index].GuestPhone = newDoc[2];
  peopleObjects.value[index].AltPhone = newDoc[3];
  peopleObjects.value[index].Unit = newDoc[4];
  peopleObjects.value[index].ArrivalDate = parseDocumentDate(newDoc[5]);
  peopleObjects.value[index].DepartureDate = parseDocumentDate(newDoc[6]);
  peopleObjects.value[index].TotalAdults = newDoc[7];
  peopleObjects.value[index].TotalChildren = newDoc[8];
  peopleObjects.value[index].DurationOfStay =
    getDayDiff({
      ArrivalDate: peopleObjects.value[index].ArrivalDate,
      DepartureDate: peopleObjects.value[index].DepartureDate,
    }) + 1;
  validateAll();
}

function isValid(): boolean {
  return peopleObjectsValidationRef.value.every((i) => i === true);
}

function parseDocumentDate(documentDate: string): Date {
  let newDateString = documentDate.substring(0, 10);
  let newDate = new Date();

  newDate.setHours(0, 0, 0, 0);

  newDate.setFullYear(
    parseInt(newDateString.substring(0, 4)),
    parseInt(newDateString.substring(5, 7)) - 1,
    parseInt(newDateString.substring(8, 10))
  );

  return newDate;
}

function parseUploadDate(uploadDate: string): Date {
  const delimiters = /[./-]/;
  let newDateStrings = uploadDate.split(delimiters);

  let month = parseInt(newDateStrings[0]) - 1;
  let day = parseInt(newDateStrings[1]);
  let year = parseInt(newDateStrings[2]);

  let newDate = new Date();

  newDate.setHours(0, 0, 0, 0);

  newDate.setFullYear(year, month, day);

  return newDate;
}

function parsePhoneNum(uploadedPhone: string): string {
  let formattedPhoneNum = uploadedPhone.replace(/\D/g, "");
  if (formattedPhoneNum.length >= 10) {
    formattedPhoneNum = formattedPhoneNum.substring(
      formattedPhoneNum.length - 10,
      formattedPhoneNum.length
    );
    formattedPhoneNum = `(${formattedPhoneNum.substring(
      0,
      3
    )}) ${formattedPhoneNum.substring(3, 6)}-${formattedPhoneNum.substring(
      6,
      10
    )}`;
  }
  return formattedPhoneNum;
}
</script>

<style>
.pm-registration {
  --content-padding: 2rem;
}
.registration-header {
  margin: 0 auto 36px auto;
  width: clamp(60%, 60%, 80%);
  font-size: 1.5rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  align-items: center;
}

.registration-header__divider-line {
  height: 1px;
  background-color: rgb(198, 198, 198);
  content: "";
  width: 100%;
}

.pm-registration__content {
  padding: var(--content-padding);
  margin: 0 auto;

  width: calc(100vw - (var(--content-padding) * 2));
  max-width: 720px;

  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.pm-registration-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;

  padding: calc(var(--content-padding) / 2);
  flex: 1;

  background-color: rgb(241, 241, 241);
  outline: 1px solid #999;
  border-radius: 0.3rem;

  & button,
  & .pretends-to-be-button {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 12rem;
  }

  & .pretends-to-be-button {
    width: 10rem;
  }
}

.document-container {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.error-text {
  color: red;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  margin: 1rem;
}

.submit-all {
  margin-bottom: 6rem;
  width: 12rem;
  height: 4rem;
  align-self: center;
  margin-top: 1rem;
}

.filename {
  font-weight: 600;
}

.spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}

.form-spinner-circle {
  margin: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-color: white white transparent transparent;
  animation: spin 0.9s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-overlay__spinner {
  margin: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: white white transparent transparent;
  animation: spin 0.9s linear infinite;
}

.loading-overlay__text {
  font-size: 2rem;
  color: white;
}

@media only screen and (max-width: 495px) {
  .loading-overlay__text {
    font-size: 1.5rem;
  }

  .loading-overlay__spinner {
    height: 3rem;
    width: 3rem;
  }
}
</style>
