<template>
  <div class="document-card">
    <div
      class="document-card__container"
      :class="{ invalid: !valid }"
      @click="modalRef.openDialog()"
    >
      <img
        class="document-card__edit"
        src="../assets/edit-icon.svg"
        alt="edit-icon"
      />
      <div class="document-card__line">
        <span class="document-card__line-label">Name:</span>
        <span class="document-card__line-content"> {{ document.Name }}</span>
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label">Email:</span>
        <span class="document-card__line-content"> {{ document.Email }}</span>
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label">Guest Phone:</span>
        <span class="document-card__line-content">
          {{ document.GuestPhone }}</span
        >
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label">Alt. Phone:</span>
        <span class="document-card__line-content">
          {{ document.AltPhone }}</span
        >
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label">Unit Booked:</span>
        <span class="document-card__line-content"> {{ document.Unit }}</span>
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label">Arrival Date:</span>
        <span class="document-card__line-content">
          {{ document.ArrivalDate.toLocaleDateString() }}</span
        >
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label">Departure Date:</span>
        <span class="document-card__line-content">
          {{ document.DepartureDate.toLocaleDateString() }}</span
        >
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label"># of Adults:</span>
        <span class="document-card__line-content">
          {{ document.TotalAdults }}</span
        >
      </div>
      <div class="document-card__line">
        <span class="document-card__line-label"># of Children:</span>
        <span class="document-card__line-content">
          {{ document.TotalChildren }}</span
        >
      </div>
    </div>
    <SlotModal ref="modalRef" @close="modalRef.closeDialog()">
      <div class="user-info__title">Registration Information:</div>
      <RegistrationForm
        acknowledge-statement=""
        document-type=""
        :document="document"
        @update-document="
          (newDoc) => {
            emitUpdate(newDoc);
          }
        "
      />
    </SlotModal>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import SlotModal from "./slotModal.vue";
import RegistrationForm from "./registrationForm.vue";

const modalRef = ref<InstanceType<typeof SlotModal>>(null);

const props = defineProps({
  valid: {
    type: Boolean,
    required: true,
  },
  document: {
    type: Object as any,
    required: true,
  },
});

const emit = defineEmits(["update-document"]);

function emitUpdate(doc: any): void {
  modalRef.value.closeDialog();
  emit("update-document", doc);
}
</script>

<style scoped>
.document-card__container {
  text-align: left;
  display: block;
  margin: 1rem;
  gap: 1rem;
  padding: 1rem;
  background-color: rgb(241, 241, 241);
  border-radius: 4px;
  width: 35rem;
  outline: 1px solid #999;
  transition: filter 400ms;

  &:hover {
    transition: filter 400ms;
    filter: drop-shadow(0 0 0.5rem rgba(0, 117, 193, 0.5));
    cursor: pointer;

    .document-card__edit {
      transition: opacity 400ms;
      opacity: 100;
    }
  }
}

.invalid {
  filter: drop-shadow(0 0 0.5rem rgba(255, 0, 0, 0.5)) !important;
}

.document-card__edit {
  width: 1rem;
  float: right;
  opacity: 0;
  transition: opacity 400ms;
}

.document-card__line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem;
}

.document-card__line-content {
  width: 17.5rem;
  word-wrap: break-word;
}

.user-info__title {
  font-size: 1.5rem;
  color: #5e5e5e;
}

@media only screen and (max-width: 720px) {
  .document-card__container {
    width: 85vw;
    margin: 1rem 0.5rem;
    padding: 0.5rem;
  }

  .document-card__line {
    margin: 0.5rem;
  }

  .document-card__line-content {
    width: 42.5vw;
    word-wrap: break-word;
  }
}
</style>
