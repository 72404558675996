<template>
  <div class="home-view">
    <div class="grid-item-container">
      <img
        src="../assets/ChairsOnBeach.png"
        alt="Chairs sitting on a beach"
        class="grid-item-img"
      />
      <p class="grid-item-header">Registration by Renter</p>
      <button @click="navigate('/registration-by-renter')">
        Rental Guests
      </button>
    </div>
    <div class="grid-item-container">
      <img
        src="../assets/CleanLinens.png"
        alt="Clean towels folded on neatly-made bed"
        class="grid-item-img"
      />
      <p class="grid-item-header">Registration by Owner</p>
      <button @click="navigate('/registration-by-owner')">Unit Owners</button>
    </div>
    <div class="grid-item-container">
      <img
        src="../assets/HouseInHand.png"
        alt="Small house in someone's palm"
        class="grid-item-img"
      />
      <p class="grid-item-header">Register Guests as PM</p>
      <button @click="navigate('/register-guests-as-pm')">
        Property Managers
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();

const navigate = function (to: string) {
  router.push(to);
};
</script>

<style>
.home-view {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-bottom: 6rem;
}

.grid-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.grid-item-img {
  border-radius: 50%;
  /* box-shadow: 2px 2px 4px rgba(25, 25, 25, 0.05); */
}

.grid-item-header {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(0, 117, 193);
}

button,
.pretends-to-be-button {
  font-size: 1.25rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  background-color: rgb(0, 117, 193);
  color: rgb(255, 255, 255);
  font-weight: 700;

  &:hover {
    cursor: pointer;
    transition: ease-in 100ms;
    background-color: rgb(0, 143, 234);
  }
}

@media only screen and (max-width: 768px) {
  .grid-item-img {
    scale: 0.8;
  }

  .home-view {
    margin-top: -3rem;
  }
}
</style>
