<template>
  <div class="checkout-results">
    <div v-if="success" class="checkout-results--success">
      <img
        class="checkout-results__icon"
        src="..\assets\success-icon.gif"
        alt="success-icon"
      />
      <div class="checkout-results__text">
        Checkout Success! Your registration details are being processed.
      </div>
    </div>

    <div v-else class="checkout-results--failure">
      <img
        class="checkout-results__icon"
        src="..\assets\failure-icon.gif"
        alt="failure-icon"
      />
      <div class="checkout-results__text">
        Cart was canceled or checkout otherwise failed. Please try again.
      </div>
    </div>

    <button style="margin-top: 2rem" @click="router.push('/')">
      Return to Home
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  success: {
    type: Boolean,
  },
});
</script>

<style>
.checkout-results {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 70vh;
  max-width: 720px;
  flex-direction: column;
}

.checkout-results__icon {
  width: 20rem;
  height: 20rem;
}

.checkout-results__text {
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  font-weight: 600;
}

/* Small devices (portrait tablets and large phones, 600px-768px) */
@media only screen and (max-width: 768px) {
  .checkout-results {
    margin: -1rem 1rem auto;
  }

  .checkout-results__icon {
    scale: 0.8;
  }
}
</style>
