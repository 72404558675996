export const AllUnits = [
  "A101",
  "A102",
  "A103",
  "A104",
  "A105",
  "A201",
  "A202",
  "A203",
  "A204",
  "A205",
  "A301",
  "A302",
  "A303",
  "A304",
  "A305",
  "A401",
  "A402",
  "A403",
  "A404",
  "A405",
  "B101",
  "B102",
  "B103",
  "B104",
  "B105",
  "B201",
  "B202",
  "B203",
  "B204",
  "B205",
  "B301",
  "B302",
  "B303",
  "B304",
  "B305",
  "B401",
  "B402",
  "B403",
  "B404",
  "B405",
  "C101",
  "C102",
  "C103",
  "C104",
  "C105",
  "C201",
  "C202",
  "C203",
  "C204",
  "C205",
  "C301",
  "C302",
  "C303",
  "C304",
  "C305",
  "C401",
  "C402",
  "C403",
  "C404",
  "C405",
  "D101",
  "D102",
  "D103",
  "D104",
  "D105",
  "D201",
  "D202",
  "D203",
  "D204",
  "D205",
  "D301",
  "D302",
  "D303",
  "D304",
  "D305",
  "D401",
  "D402",
  "D403",
  "D404",
  "D405",
  "E101",
  "E102",
  "E103",
  "E104",
  "E105",
  "E201",
  "E202",
  "E203",
  "E204",
  "E205",
  "E301",
  "E302",
  "E303",
  "E304",
  "E305",
  "F101",
  "F102",
  "F103",
  "F104",
  "F105",
  "F201",
  "F202",
  "F203",
  "F204",
  "F205",
  "F301",
  "F302",
  "F303",
  "F304",
  "F305",
  "G101",
  "G102",
  "G103",
  "G104",
  "G105",
  "G201",
  "G202",
  "G203",
  "G204",
  "G205",
  "G301",
  "G302",
  "G303",
  "G304",
  "G305",
  "G401",
  "G402",
  "G403",
  "G404",
  "G405",
];
