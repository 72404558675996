import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pm-registration" }
const _hoisted_2 = {
  key: 0,
  class: "loading-overlay"
}
const _hoisted_3 = { class: "pm-registration__content" }
const _hoisted_4 = { class: "pm-registration-box" }
const _hoisted_5 = { class: "pretends-to-be-button" }
const _hoisted_6 = {
  key: 1,
  class: "filename"
}
const _hoisted_7 = { class: "document-container" }
const _hoisted_8 = {
  key: 2,
  class: "error-text"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "spinner"
}

import { ref } from "vue";
import * as Papa from "papaparse";
import {
  stringToDate,
  getNextSaturday,
  dateToYyyyMmDdString,
} from "@/helpers/dateHelpers";
import DocumentCard from "@/components/documentCard.vue";
import { toast, type ToastOptions } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegistrationByPM',
  setup(__props) {

const peopleObjects = ref(Array<any>());
const peopleObjectsValidationRef = ref(Array<boolean>());
const fileName = ref("");
const uploadPending = ref(false);
const isPending = ref(false);

const EMAIL_REGEX = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);
const PHONE_REGEX = new RegExp("^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$");

async function handleSubmit(people: any[]) {
  validateAll();
  if (isValid()) {
    isPending.value = true;
    people.forEach((person) => {
      person.ArrivalDate = dateToYyyyMmDdString(person.ArrivalDate);
      person.DepartureDate = dateToYyyyMmDdString(person.DepartureDate);
    });
    try {
      const res = await fetch(
        "https://app-y4uhxvobbq-uc.a.run.app/property-manager-checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            docArray: people,
          }),
        }
      );
      const data = await res.json();
      window.location.href = data.checkoutSessionUrl;
    } catch (error) {
      console.error(error);

      toast("Something went wrong... please try again", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      } as ToastOptions);

      isPending.value = false;
    }
  }
}

function getDayDiff(submittedDocument: any): number {
  let Difference_In_Time =
    submittedDocument.DepartureDate.getTime() -
    submittedDocument.ArrivalDate.getTime();

  // Calculating the no. of days between
  // two dates
  let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  return Difference_In_Days;
}

/**
 * Initiates a download of the Excel template file.
 */
function downloadExcelFileToClient() {
  const filename = "bulk-upload-template.xlsx";
  const pseudoAnchorTag = document.createElement("a");
  pseudoAnchorTag.href = filename;
  pseudoAnchorTag.download = filename;

  pseudoAnchorTag.click();
  pseudoAnchorTag.remove();
}

/**
 * Validates the uploaded CSV file or berates the user severely if it couldn't be validated.
 */
async function handleFileUpload(event: Event): Promise<void> {
  const target = event.target as HTMLInputElement;
  if (!target || !target.files) return;

  uploadPending.value = true;
  const uploadedFile = target.files[0];

  let testText = await uploadedFile.text();
  fileName.value = uploadedFile.name;

  Papa.parse(testText, {
    complete: function (result) {
      let people = result.data as string[][];
      people.splice(0, 4);
      people.forEach((person) => {
        let entryCounter = 0;
        person.splice(0, 1);
        person.forEach((entry) => {
          if (entry.length !== 0) entryCounter += 1;
        });

        if (entryCounter !== 0) {
          const personData = {
            Name: person[0],
            Email: person[1],
            GuestPhone: parsePhoneNum(person[2]),
            AltPhone: parsePhoneNum(person[3]),
            Unit: person[4],
            ArrivalDate: parseUploadDate(person[5]),
            DepartureDate: parseUploadDate(person[6]),
            TotalAdults: person[7].toString(),
            TotalChildren: person[8].toString(),
            RegistrationDate: new Date(),
            Acknowledgement: true,
            DurationOfStay:
              getDayDiff({
                ArrivalDate: parseUploadDate(person[5]),
                DepartureDate: parseUploadDate(person[6]),
              }) + 1,
          };
          peopleObjects.value.push(personData);
          peopleObjectsValidationRef.value.push(true);
        }
      });
    },
  });
  uploadPending.value = false;
}

function validateAll(): void {
  let nameIsValid,
    emailIsValid,
    guestPhoneIsValid,
    altPhoneIsValid,
    unitIsValid,
    datesValid,
    numAdultsIsValid,
    numChildrenIsValid;

  peopleObjects.value.forEach((person, index) => {
    nameIsValid = validateFilled(person.Name);
    emailIsValid = validateAgainstRegex(person.Email, EMAIL_REGEX);
    guestPhoneIsValid = validateAgainstRegex(person.GuestPhone, PHONE_REGEX);
    unitIsValid = validateFilled(person.Unit);
    datesValid = validateDates(person);
    numAdultsIsValid =
      validateFilled(person.TotalAdults) && person.TotalAdults > 0;
    numChildrenIsValid = validateFilled(person.TotalChildren);

    if (validateFilled(person.AltPhone))
      altPhoneIsValid = validateAgainstRegex(person.AltPhone, PHONE_REGEX);
    else altPhoneIsValid = true;

    if (
      nameIsValid &&
      emailIsValid &&
      guestPhoneIsValid &&
      altPhoneIsValid &&
      unitIsValid &&
      datesValid &&
      numAdultsIsValid &&
      numChildrenIsValid
    )
      peopleObjectsValidationRef.value[index] = true;
    else peopleObjectsValidationRef.value[index] = false;
  });
}

function validateFilled(info: string): boolean {
  return info.length !== 0;
}

function validateAgainstRegex(info: string, regex: RegExp): boolean {
  return regex.test(info) && validateFilled(info);
}

function validateDates(info: any): boolean {
  let departureDate = info.DepartureDate;
  let arrivalDate = info.ArrivalDate;

  if (!isValidDate(departureDate) || !isValidDate(arrivalDate)) return false;

  if (
    departureDate < stringToDate(dateToYyyyMmDdString(new Date())) ||
    arrivalDate < stringToDate(dateToYyyyMmDdString(new Date()))
  )
    return false;

  if (arrivalDate >= departureDate) return false;

  return validateFilled(arrivalDate) && validateFilled(departureDate);
}

function isValidDate(d: Date) {
  return !isNaN(d.getTime());
}

function updateDocument(newDoc: any, index: number): void {
  peopleObjects.value[index].Name = newDoc[0];
  peopleObjects.value[index].Email = newDoc[1];
  peopleObjects.value[index].GuestPhone = newDoc[2];
  peopleObjects.value[index].AltPhone = newDoc[3];
  peopleObjects.value[index].Unit = newDoc[4];
  peopleObjects.value[index].ArrivalDate = parseDocumentDate(newDoc[5]);
  peopleObjects.value[index].DepartureDate = parseDocumentDate(newDoc[6]);
  peopleObjects.value[index].TotalAdults = newDoc[7];
  peopleObjects.value[index].TotalChildren = newDoc[8];
  peopleObjects.value[index].DurationOfStay =
    getDayDiff({
      ArrivalDate: peopleObjects.value[index].ArrivalDate,
      DepartureDate: peopleObjects.value[index].DepartureDate,
    }) + 1;
  validateAll();
}

function isValid(): boolean {
  return peopleObjectsValidationRef.value.every((i) => i === true);
}

function parseDocumentDate(documentDate: string): Date {
  let newDateString = documentDate.substring(0, 10);
  let newDate = new Date();

  newDate.setHours(0, 0, 0, 0);

  newDate.setFullYear(
    parseInt(newDateString.substring(0, 4)),
    parseInt(newDateString.substring(5, 7)) - 1,
    parseInt(newDateString.substring(8, 10))
  );

  return newDate;
}

function parseUploadDate(uploadDate: string): Date {
  const delimiters = /[./-]/;
  let newDateStrings = uploadDate.split(delimiters);

  let month = parseInt(newDateStrings[0]) - 1;
  let day = parseInt(newDateStrings[1]);
  let year = parseInt(newDateStrings[2]);

  let newDate = new Date();

  newDate.setHours(0, 0, 0, 0);

  newDate.setFullYear(year, month, day);

  return newDate;
}

function parsePhoneNum(uploadedPhone: string): string {
  let formattedPhoneNum = uploadedPhone.replace(/\D/g, "");
  if (formattedPhoneNum.length >= 10) {
    formattedPhoneNum = formattedPhoneNum.substring(
      formattedPhoneNum.length - 10,
      formattedPhoneNum.length
    );
    formattedPhoneNum = `(${formattedPhoneNum.substring(
      0,
      3
    )}) ${formattedPhoneNum.substring(3, 6)}-${formattedPhoneNum.substring(
      6,
      10
    )}`;
  }
  return formattedPhoneNum;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (uploadPending.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "loading-overlay__spinner" }, null, -1),
          _createElementVNode("p", { class: "loading-overlay__text" }, "Uploading file... Please wait", -1)
        ])))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createElementVNode("h3", { class: "registration-header" }, [
      _createElementVNode("div", { class: "registration-header__divider-line" }),
      _createTextVNode(" Registration by PM "),
      _createElementVNode("div", { class: "registration-header__divider-line" })
    ], -1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", { class: "pm-registration-box" }, [
        _cache[2] || (_cache[2] = _createElementVNode("p", null, "Download bulk registration form (xlsx)", -1)),
        _createElementVNode("button", {
          type: "submit",
          onClick: downloadExcelFileToClient
        }, " Download ")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "Upload bulk registration CSV", -1)),
        _createElementVNode("label", _hoisted_5, [
          _cache[3] || (_cache[3] = _createTextVNode(" Upload ")),
          _createElementVNode("input", {
            type: "file",
            accept: ".csv",
            style: {"display":"none"},
            onChange: handleFileUpload
          }, null, 32)
        ])
      ])
    ]),
    (peopleObjects.value.length !== 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Registrations from \"" + _toDisplayString(fileName.value) + "\": ", 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(peopleObjects.value, (document, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "document-cards"
        }, [
          (!isPending.value)
            ? (_openBlock(), _createBlock(DocumentCard, {
                key: 0,
                valid: peopleObjectsValidationRef.value[index],
                document: document,
                onUpdateDocument: 
            (newDoc) => {
              updateDocument(newDoc, index);
            }
          
              }, null, 8, ["valid", "document", "onUpdateDocument"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    (!isValid())
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Submission was invalid. Please find the highlighted registration(s) and correct them. "))
      : _createCommentVNode("", true),
    (peopleObjects.value.length !== 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "submit-all",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleSubmit(peopleObjects.value))),
          disabled: isPending.value
        }, [
          (!isPending.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Submit"))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[5] || (_cache[5] = [
                _createElementVNode("div", { class: "form-spinner-circle" }, null, -1)
              ])))
        ], 8, _hoisted_9))
      : _createCommentVNode("", true)
  ]))
}
}

})