import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "faq-view" }

import InfoAccordion from "@/components/infoAccordion.vue";
import { FAQ_ENTRIES } from "@/constants/FAQs";

export default /*@__PURE__*/_defineComponent({
  __name: 'FaqView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "faq-view__header-box" }, [
      _createElementVNode("hr", { class: "horizontal-rule--header" }),
      _createElementVNode("div", { class: "faq-view__heading" }, "Frequently Asked Questions"),
      _createElementVNode("hr", { class: "horizontal-rule--header" })
    ], -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FAQ_ENTRIES), (entry) => {
      return (_openBlock(), _createBlock(InfoAccordion, {
        key: entry.label,
        label: entry.label
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(entry.content), 1)
        ]),
        _: 2
      }, 1032, ["label"]))
    }), 128))
  ]))
}
}

})