export function dateToYyyyMmDdString(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function stringToDate(oldDate: string): Date {
  oldDate =
    oldDate.substring(0, 4) +
    " " +
    oldDate.substring(5, 7) +
    " " +
    oldDate.substring(8, 10);

  const newDate = new Date(oldDate);
  return newDate;
}

export function getNextSaturday(date: Date): string {
  const dayOfWeek = date.getDay();
  const nextSaturday = new Date(date);

  nextSaturday.setDate(date.getDate() + (6 - dayOfWeek));

  return dateToYyyyMmDdString(nextSaturday);
}

export function getPreviousSaturday(date: Date): string {
  const dayOfWeek = date.getDay();
  const previousSaturday = new Date(date);

  previousSaturday.setDate(date.getDate() + (6 - dayOfWeek));
  previousSaturday.setDate(previousSaturday.getDate() - 7);

  return dateToYyyyMmDdString(previousSaturday);
}

export function tzAgnosticDateUTC(date: Date): string {
  return dateToYyyyMmDdString(new Date(date.getTime()));
}
