import { ListEntry } from "@/types/listEntry";

export const ADAGIO_RULES = [
  {
    label: "OCCUPANCY LIMIT: ",
    content:
      "8 people per pool side unit and 10 people per gulf front unit (not counting children 2 years old and under).",
  },
  {
    label: "PARKING LIMIT: ",
    content:
      "2 vehicles per unit.  If you have rented a beachfront unit, you have an assigned parking space in the parking garage and must use this space rather than a common parking space in the outside lot. If you have a third vehicle in your party, please contact the association for alternate options.",
  },
  {
    label: "SPEED LIMIT: ",
    content: "The speed limit for all vehicles is 10 MPH.",
  },
  {
    label: "GOLF CARTS: ",
    content:
      'No golf carts are permitted on site.  This restriction includes "street-legal" vehicles that can operate on some, but not all, public roads.',
  },
  {
    label: "RECREATIONAL VEHICLES: ",
    content:
      "No Recreational vehicles larger than one parking space are allowed on site. ",
  },
  {
    label: "TRAILERS: ",
    content: "No trailers of any kind are allowed on site.",
  },
  {
    label: "PERSONAL PROPERTY: ",
    content:
      "Personal property of unit owners, tenants, guests, invitees or occupants shall not be stored outside their units.  No items may be left in hallways.",
  },
  {
    label: "SMOKING ",
    content:
      "is NOT permitted within the pool and/or spa areas, around the fence to these areas or in the common areas of the buildings (i.e, garages, hallways, stairwells, elevators etc...)",
  },
  {
    label: "BICYCLE PARKING ",
    content:
      "is provided at the stairwells of pool side buildings and in the garages of gulf front buildings. If parking is full on pool side buildings, please park your bicycle under one of the gulf front buildings in the striped area provided.  Do not lock bikes to trees, columns, or railings.  They will be removed.",
  },
  {
    label: "BALCONIES: ",
    content:
      "Do not hang anything (towels, clothes, etc.) off of balconies.  Do not throw, drop or pour anything from the balconies.",
  },
  {
    label: "OUTDOOR COOKING ",
    content:
      "is only permitted in one of four (4) designated grill areas on the property.  No cooking on patios or balconies.",
  },
  {
    label: "GARBAGE: ",
    content:
      "Do not leave your garbage in the hallways or parking garages.  There are seven dumpsters on site.  Do not leave bags or items outside of the dumpsters.",
  },
  {
    label: "PETS: ",
    content:
      "Only Adagio owners are permitted to bring pets to Adagio.  Owner guests may not bring pets.",
  },
  {
    label: "NOISE: ",
    content:
      "No noise is permitted that would disturb your neighbors; especially after 10 p.m. pursuant to Walton County Ordinance.",
  },
  {
    label: "FIRE & FIREWORKS: ",
    content:
      "No fires are permitted on Adagio's beach.  County beach fire permits do not supersede this restriction.  No fireworks on Adagio property.",
  },
] as ListEntry[];

export const POOL_AND_SPA_RULES = [
  {
    label: "SPA RULES: ",
    content:
      "Children 4-12 must be accompanied by a parent.  The spa is not a kiddie pool.  Spa is limited to Ages 21 and older after 6 PM.  Please be considerate of others.  Further rules apply as posted in the spa area.",
  },
  {
    content:
      "Towels or other personal items do not reserve chairs or tables.  Unattended personal items may be collected to the lost and found.",
  },
  {
    content: "Observe all posted signs.",
  },
  {
    content: "Do not jump or dive from spillways or fountains.",
  },
  {
    label: "NO LIFEGUARD ",
    content: "is on duty on the beach.",
    warning: "SWIM AT YOUR OWN RISK.",
  },
  {
    label: "NO GLASS ",
    content: "of any kind is permitted on the beach.",
  },
] as ListEntry[];

export const BEACH_RULES = [
  {
    content:
      "Personal chairs and umbrellas are permitted on the designated portion of the beach; however, tents and tarps are NOT allowed. ",
  },
  {
    content:
      "Umbrellas shall be no larger than 8’ diameter touching the ground at a single point (pole). ",
  },
  {
    content:
      "There shall be a 4’ walkway between adjacent umbrellas unless they are in the same party. ",
  },
  {
    content:
      "Square umbrellas with counterweights or sand anchors at corners shall be considered tents. (cool cabana or similar)",
  },
  {
    content:
      "Infant shelters are permitted but can be no larger than is necessary to shade the infant/toddler.",
  },
  {
    content:
      "All personal belongings must be removed from the beach no later than 30 minutes after sunset (full dark) and shall be subject to removal and disposal by the county.",
  },
  {
    label: "NO LIFEGUARD ",
    content: "is on duty on the beach.",
    warning: "SWIM AT YOUR OWN RISK.",
  },
  {
    label: "NO GLASS ",
    content: "of any kind is permitted on the beach.",
  },
] as ListEntry[];
