import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rules-view" }

import RuleList from "@/components/ruleList.vue";
import {
  ADAGIO_RULES,
  POOL_AND_SPA_RULES,
  BEACH_RULES,
} from "@/constants/ruleLists";

export default /*@__PURE__*/_defineComponent({
  __name: 'RulesView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"rules-view__header-box\"><hr class=\"horizontal-rule--header\"><div class=\"rules-view__heading\">ADAGIO RULES</div><hr class=\"horizontal-rule--header\"></div><div class=\"rules-view__subtitle\"> Welcome to Adagio! The Owner’s Association has established rules to protect our property and for the mutual benefit of all guests and owners as they enjoy our facility. This is an abbreviated list of Adagio&#39;s rules that apply to rental activity. </div>", 2)),
    _createVNode(RuleList, { rules: _unref(ADAGIO_RULES) }, null, 8, ["rules"]),
    _createVNode(RuleList, {
      rules: _unref(POOL_AND_SPA_RULES),
      title: "POOL & SPA RULES"
    }, null, 8, ["rules"]),
    _createVNode(RuleList, {
      rules: _unref(BEACH_RULES),
      title: "BEACH RULES"
    }, null, 8, ["rules"]),
    _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "horizontal-rule--footer" }, null, -1))
  ]))
}
}

})