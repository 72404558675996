import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/edit-icon.svg'


const _hoisted_1 = { class: "document-card" }
const _hoisted_2 = { class: "document-card__line" }
const _hoisted_3 = { class: "document-card__line-content" }
const _hoisted_4 = { class: "document-card__line" }
const _hoisted_5 = { class: "document-card__line-content" }
const _hoisted_6 = { class: "document-card__line" }
const _hoisted_7 = { class: "document-card__line-content" }
const _hoisted_8 = { class: "document-card__line" }
const _hoisted_9 = { class: "document-card__line-content" }
const _hoisted_10 = { class: "document-card__line" }
const _hoisted_11 = { class: "document-card__line-content" }
const _hoisted_12 = { class: "document-card__line" }
const _hoisted_13 = { class: "document-card__line-content" }
const _hoisted_14 = { class: "document-card__line" }
const _hoisted_15 = { class: "document-card__line-content" }
const _hoisted_16 = { class: "document-card__line" }
const _hoisted_17 = { class: "document-card__line-content" }
const _hoisted_18 = { class: "document-card__line" }
const _hoisted_19 = { class: "document-card__line-content" }

import { ref } from "vue";
import SlotModal from "./slotModal.vue";
import RegistrationForm from "./registrationForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'documentCard',
  props: {
  valid: {
    type: Boolean,
    required: true,
  },
  document: {
    type: Object as any,
    required: true,
  },
},
  emits: ["update-document"],
  setup(__props, { emit: __emit }) {

const modalRef = ref<InstanceType<typeof SlotModal>>(null);

const props = __props;

const emit = __emit;

function emitUpdate(doc: any): void {
  modalRef.value.closeDialog();
  emit("update-document", doc);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["document-card__container", { invalid: !__props.valid }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (modalRef.value.openDialog()))
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("img", {
        class: "document-card__edit",
        src: _imports_0,
        alt: "edit-icon"
      }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "document-card__line-label" }, "Name:", -1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(__props.document.Name), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "document-card__line-label" }, "Email:", -1)),
        _createElementVNode("span", _hoisted_5, _toDisplayString(__props.document.Email), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "document-card__line-label" }, "Guest Phone:", -1)),
        _createElementVNode("span", _hoisted_7, _toDisplayString(__props.document.GuestPhone), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "document-card__line-label" }, "Alt. Phone:", -1)),
        _createElementVNode("span", _hoisted_9, _toDisplayString(__props.document.AltPhone), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "document-card__line-label" }, "Unit Booked:", -1)),
        _createElementVNode("span", _hoisted_11, _toDisplayString(__props.document.Unit), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[8] || (_cache[8] = _createElementVNode("span", { class: "document-card__line-label" }, "Arrival Date:", -1)),
        _createElementVNode("span", _hoisted_13, _toDisplayString(__props.document.ArrivalDate.toLocaleDateString()), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "document-card__line-label" }, "Departure Date:", -1)),
        _createElementVNode("span", _hoisted_15, _toDisplayString(__props.document.DepartureDate.toLocaleDateString()), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "document-card__line-label" }, "# of Adults:", -1)),
        _createElementVNode("span", _hoisted_17, _toDisplayString(__props.document.TotalAdults), 1)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "document-card__line-label" }, "# of Children:", -1)),
        _createElementVNode("span", _hoisted_19, _toDisplayString(__props.document.TotalChildren), 1)
      ])
    ], 2),
    _createVNode(SlotModal, {
      ref_key: "modalRef",
      ref: modalRef,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (modalRef.value.closeDialog()))
    }, {
      default: _withCtx(() => [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "user-info__title" }, "Registration Information:", -1)),
        _createVNode(RegistrationForm, {
          "acknowledge-statement": "",
          "document-type": "",
          document: __props.document,
          onUpdateDocument: _cache[1] || (_cache[1] = 
          (newDoc) => {
            emitUpdate(newDoc);
          }
        )
        }, null, 8, ["document"])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})