import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "rule-list" }
const _hoisted_2 = {
  key: 0,
  class: "rules-view__header-box"
}
const _hoisted_3 = { class: "rule-list__title" }
const _hoisted_4 = { class: "rule-list__list" }
const _hoisted_5 = { class: "rule-list__item--label" }
const _hoisted_6 = { class: "rule-list__item--content" }
const _hoisted_7 = { class: "rule-list__item--warning" }

import { ListEntry } from "@/types/listEntry";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ruleList',
  props: {
  title: {
    type: String,
  },
  rules: {
    type: Array as PropType<ListEntry[]>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "horizontal-rule--section" }, null, -1)),
          _createElementVNode("div", _hoisted_3, _toDisplayString(__props.title), 1),
          _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "horizontal-rule--section" }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rules, (rule) => {
        return (_openBlock(), _createElementBlock("li", {
          key: rule.content,
          class: "rule-list__item"
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(rule.label), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(rule.content), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(rule.warning), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})