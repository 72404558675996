import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import RegistrationForm from "@/components/registrationForm.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'RegistrationByRenter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "registration-header" }, [
      _createElementVNode("div", { class: "registration-header__divider-line" }),
      _createTextVNode(" Registration by renter "),
      _createElementVNode("div", { class: "registration-header__divider-line" })
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "registration-form__instructions" }, " Enter your contact information in the fields below. All information is confidential and used only to contact guests, if necessary, regarding their stay at Adagio. ", -1)),
    _createVNode(RegistrationForm, {
      "acknowledge-statement": "Check the box to acknowledge you have reviewed and\r\n      accept the rules found at the link above*",
      "document-type": "Renter Registration"
    })
  ], 64))
}
}

})