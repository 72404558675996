import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/adagioLogo.svg'
import _imports_1 from '../../assets/burger-icon.svg'


const _hoisted_1 = { class: "mobile-nav" }
const _hoisted_2 = { class: "nav-container" }
const _hoisted_3 = { class: "vertical" }

import { ref } from "vue";
import InfoAccordion from "../infoAccordion.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'mobileNav',
  setup(__props) {

const showMenu = ref(false);
const isActive = ref(false);
const isOpen = ref();

function toggleMenu(): void {
  showMenu.value = !showMenu.value;
  isOpen.value.isOpen = false;
  isActive.value = false;
}

function toggleIsActive(): void {
  isActive.value = !isActive.value;
}

function closeMenu(): void {
  showMenu.value = false;
  isOpen.value.isOpen = false;
  isActive.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/",
          onClick: closeMenu
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              class: "logo",
              src: _imports_0,
              alt: "Adagio Logo"
            }, null, -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", {
          class: "nav-container__hamburger-menu",
          onClick: toggleMenu
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("button", { class: "nav-container__button" }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Adagio Logo"
            })
          ], -1)
        ]))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["nav-group", { 'nav-group--hidden': !showMenu.value }])
      }, [
        _createElementVNode("div", { onClick: toggleMenu }, [
          _createVNode(_component_router_link, {
            class: "menu-item",
            to: "/"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ]),
        _createVNode(InfoAccordion, {
          ref_key: "isOpen",
          ref: isOpen,
          class: _normalizeClass(["accordion", { active: isActive.value }]),
          label: "Register Rental Guest(s)",
          onClick: toggleIsActive
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", { onClick: toggleMenu }, [
                _createVNode(_component_router_link, {
                  class: "menu-item",
                  to: "/registration-by-renter"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Registration by renter")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", { onClick: toggleMenu }, [
                _createVNode(_component_router_link, {
                  class: "menu-item",
                  to: "/registration-by-owner"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Registration by owner")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", { onClick: toggleMenu }, [
                _createVNode(_component_router_link, {
                  class: "menu-item",
                  to: "/register-guests-as-pm"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Register guests as PM")
                  ])),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", { onClick: toggleMenu }, [
          _createVNode(_component_router_link, {
            to: "/adagio-rules",
            class: "menu-item"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Adagio Rules")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", { onClick: toggleMenu }, [
          _createVNode(_component_router_link, {
            to: "/extra-vehicle-parking",
            class: "menu-item"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Extra Vehicle Parking ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", { onClick: toggleMenu }, [
          _createVNode(_component_router_link, {
            to: "/faq",
            class: "menu-item"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" FAQ ")
            ])),
            _: 1
          })
        ])
      ], 2)
    ])
  ]))
}
}

})