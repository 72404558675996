import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-accordion" }
const _hoisted_2 = { class: "info-accordion__label-container" }
const _hoisted_3 = { class: "info-accordion__label" }

import { ref } from "vue";
import ChevronDown from "./chevronDown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'infoAccordion',
  props: {
  label: {
    type: String,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const isOpen = ref(false);
__expose({ isOpen });

function toggleAccordion(): void {
  isOpen.value = !isOpen.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["info-accordion__button", { active: isOpen.value }]),
      onClick: toggleAccordion
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(__props.label), 1),
        _createVNode(ChevronDown, {
          class: _normalizeClass({ active: isOpen.value })
        }, null, 8, ["class"])
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["info-accordion__text", { 'info-accordion__text--visible': isOpen.value }]),
      onClick: _withModifiers(toggleAccordion, ["prevent"])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1))
  ]))
}
}

})