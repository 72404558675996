import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/close-icon.svg'


const _hoisted_1 = { class: "modal-content" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'slotModal',
  setup(__props, { expose: __expose }) {

__expose({
  closeDialog,
  openDialog,
});

const dialogRef = ref<HTMLDialogElement | null>(null);

function closeDialog() {
  dialogRef.value?.close();
}

function openDialog() {
  dialogRef.value?.showModal();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "dialogRef",
    ref: dialogRef,
    class: "modal-overlay"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("img", {
        src: _imports_0,
        alt: "close-modal-icon",
        class: "close-icon",
        onClick: closeDialog
      })
    ])
  ], 512))
}
}

})