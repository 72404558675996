export enum formFieldEnum {
  NAME,
  EMAIL,
  PHONE,
  ALT_PHONE,
  UNIT_BOOKED,
  ARRIVAL_DATE,
  DEPARTURE_DATE,
  NUM_ADULTS,
  NUM_CHILDREN,
  VERIFY,
}
