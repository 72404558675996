<template>
  <dialog ref="dialogRef" class="modal-overlay">
    <div class="modal-content">
      <slot />
      <img
        src="../assets/close-icon.svg"
        alt="close-modal-icon"
        class="close-icon"
        @click="closeDialog"
      />
    </div>
  </dialog>
</template>

<script setup lang="ts">
import { ref, defineExpose } from "vue";

defineExpose({
  closeDialog,
  openDialog,
});

const dialogRef = ref<HTMLDialogElement | null>(null);

function closeDialog() {
  dialogRef.value?.close();
}

function openDialog() {
  dialogRef.value?.showModal();
}
</script>

<style scoped>
.modal-overlay {
  border-radius: 10px;
  border: none;
  filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.5));

  &::backdrop {
    backdrop-filter: blur(3px);
  }
}

.modal-content {
  padding: 1rem;
  border-radius: 10px;
  width: 45rem;
}

.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
}

.close-icon:hover {
  cursor: pointer;
}

@media only screen and (max-width: 720px) {
  .modal-content {
    padding: 0;
    width: 100%;
    margin: 0;
  }
}
</style>
