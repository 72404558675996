import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/adagioLogo.svg'


const _hoisted_1 = { class: "desktop-nav" }
const _hoisted_2 = { class: "nav-container" }
const _hoisted_3 = { class: "nav-container__nav-group" }
const _hoisted_4 = { class: "nav-container__dropdown" }
const _hoisted_5 = { class: "nav-container__dropdown-container" }

import { ref } from "vue";
import ChevronDown from "@/components/chevronDown.vue";
import NavDropdown from "@/components/navigation/navDropdown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'desktopNav',
  setup(__props, { expose: __expose }) {

let showDropdown = ref(false);
__expose({ showDropdown });

const registrationDropdownProps = ref([
  {
    id: 1,
    route: "/registration-by-renter",
    text: "Registration by renter",
  },
  {
    id: 2,
    route: "/registration-by-owner",
    text: "Registration by owner",
  },
  {
    id: 3,
    route: "/register-guests-as-pm",
    text: "Register guests as PM",
  },
]);

const toggleRegisterDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Adagio Logo"
            }, null, -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Home")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", {
                class: "nav-container__dropdown-toggle",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleRegisterDropdown()), ["stop"]))
              }, [
                _cache[3] || (_cache[3] = _createTextVNode(" Register Rental Guest(s) ")),
                _createVNode(ChevronDown, {
                  class: _normalizeClass({ active: _unref(showDropdown) })
                }, null, 8, ["class"])
              ]),
              _createVNode(NavDropdown, {
                class: _normalizeClass(["nav-dropdown", { 'nav-dropdown--active': _unref(showDropdown) }]),
                "nav-dropdown-props": registrationDropdownProps.value
              }, null, 8, ["class", "nav-dropdown-props"])
            ])
          ]),
          _createVNode(_component_router_link, { to: "/adagio-rules" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Adagio Rules")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/extra-vehicle-parking" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Extra Vehicle Parking ")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/faq" }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" FAQ ")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})