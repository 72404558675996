<template>
  <div class="desktop-nav">
    <nav>
      <div class="nav-container">
        <router-link to="/">
          <img src="../../assets/adagioLogo.svg" alt="Adagio Logo" />
        </router-link>

        <div class="nav-container__nav-group">
          <router-link to="/">Home</router-link>
          <div class="nav-container__dropdown">
            <div class="nav-container__dropdown-container">
              <a
                class="nav-container__dropdown-toggle"
                @click.stop="toggleRegisterDropdown()"
              >
                Register Rental Guest(s)
                <ChevronDown :class="{ active: showDropdown }" />
              </a>
              <NavDropdown
                class="nav-dropdown"
                :class="{ 'nav-dropdown--active': showDropdown }"
                :nav-dropdown-props="registrationDropdownProps"
              />
            </div>
          </div>
          <router-link to="/adagio-rules">Adagio Rules</router-link>
          <router-link to="/extra-vehicle-parking">
            Extra Vehicle Parking
          </router-link>
          <router-link to="/faq"> FAQ </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ref, defineExpose } from "vue";
import ChevronDown from "@/components/chevronDown.vue";
import NavDropdown from "@/components/navigation/navDropdown.vue";

let showDropdown = ref(false);
defineExpose({ showDropdown });

const registrationDropdownProps = ref([
  {
    id: 1,
    route: "/registration-by-renter",
    text: "Registration by renter",
  },
  {
    id: 2,
    route: "/registration-by-owner",
    text: "Registration by owner",
  },
  {
    id: 3,
    route: "/register-guests-as-pm",
    text: "Register guests as PM",
  },
]);

const toggleRegisterDropdown = () => {
  showDropdown.value = !showDropdown.value;
};
</script>

<style scoped>
nav {
  width: 100%;
  background-color: white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  padding-block: 16px;
  margin-bottom: 2rem;
}

.nav-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
}

.nav-container__nav-group {
  text-wrap: nowrap;
}

nav img {
  height: 4.375rem;
  width: auto;
}

nav a {
  /* margin: 0 1rem; */
  padding: 0 1.125rem;
  font-size: 18px;
  color: rgb(0, 117, 193);
  text-decoration: none;
}

nav a:hover {
  color: rgb(0, 73, 124);
}

nav a.router-link-exact-active {
  color: rgb(27, 27, 27);
  text-decoration: underline solid rgb(27, 27, 27) 2px;
  text-underline-offset: 10px;
}

.nav-container__dropdown-toggle {
  cursor: pointer;
  position: relative;
}

.nav-container__dropdown {
  display: inline-block;
}

.nav-container__dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nav-dropdown {
  position: absolute;
  transform: translate(-1rem, 0rem);
  box-sizing: inherit;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}

.nav-dropdown--active {
  opacity: 1 !important;
  transform: translate(-1rem, 2rem);
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: all;
}

.nav-dropdown > a {
  color: rgb(94, 94, 94);
}

.nav-dropdown > a.router-link-exact-active {
  color: rgb(94, 94, 94);
  text-decoration: none;
  font-weight: 700;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px-768px) */
@media only screen and (max-width: 768px) {
}

/* Medium devices (landscape tablets, 768px-992px) */
@media only screen and (max-width: 992px) {
}

/* Large devices (laptops/desktops, 992px-1200px) */
@media only screen and (max-width: 1200px) {
  nav img {
    height: 3rem;
  }
}
</style>
