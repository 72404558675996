import { formFieldEnum } from "@/types/formFieldEnum";
import { vehicleFieldEnum } from "@/types/vehicleFieldEnum";

export function validateFilled(
  formFields: Array<any>,
  index: formFieldEnum | vehicleFieldEnum
): boolean {
  if (formFields[index].toString().length !== 0) return false;
  else return true;
}

export function validateAgainstRegex(
  formFields: Array<any>,
  index: formFieldEnum | vehicleFieldEnum,
  regex: RegExp
): boolean {
  if (regex.test(formFields[index].toString())) return false;
  else return true;
}
