import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vue3Toastify, {
  type ToastContainerOptions,
} from "../node_modules/vue3-toastify/dist/index";
import "vue3-toastify/dist/index.css";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

createApp(App)
  .use(router)
  .use(Vue3Toastify, {
    autoClose: 3000,
    // ...
  } as ToastContainerOptions)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");

//Carter - put this wherever you need.

// toast("Toast String", {
//   type: "error",
//   position: toast.POSITION.TOP_CENTER,
// } as ToastOptions);
