import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, unref as _unref, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/search-icon.svg'


const _hoisted_1 = { class: "email-search" }
const _hoisted_2 = { class: "search-registration__icon-container" }
const _hoisted_3 = {
  key: 1,
  class: "spinner"
}
const _hoisted_4 = { class: "search-registration__form-input-container" }
const _hoisted_5 = {
  key: 0,
  class: "no-spots-available"
}
const _hoisted_6 = {
  key: 1,
  class: "search-registration_results-text"
}
const _hoisted_7 = {
  key: 2,
  class: "search-registration__no-results"
}
const _hoisted_8 = ["onInput"]
const _hoisted_9 = { class: "document-radio__container" }
const _hoisted_10 = ["value", "id"]
const _hoisted_11 = { class: "document-info__container" }
const _hoisted_12 = { class: "document-info__line" }
const _hoisted_13 = { class: "document-info__line-content" }
const _hoisted_14 = { class: "document-info__line" }
const _hoisted_15 = { class: "document-info__line-content" }
const _hoisted_16 = { class: "document-info__line" }
const _hoisted_17 = { class: "document-info__line-content" }
const _hoisted_18 = { class: "document-info__line" }
const _hoisted_19 = { class: "document-info__line-content" }
const _hoisted_20 = { class: "registration-form__button-submit" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 1,
  class: "submit spinner"
}

import { ref } from "vue";
import { vehicleFieldEnum } from "@/types/vehicleFieldEnum";
import { UserDoc } from "@/types/userDoc";
import {
  stringToDate,
  getNextSaturday,
  getPreviousSaturday,
  dateToYyyyMmDdString,
} from "@/helpers/dateHelpers";
import { validateFilled } from "@/helpers/validationHelpers";
import { toast, type ToastOptions } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExtraVehicleParking',
  setup(__props) {

const emailRef = ref("");
const searchedEmailRef = ref("");

const formInputModel = ref(["", "", ""]);
const formValidationRefs = ref([false, false, false, false]);

const userRegistrations = ref(Array<UserDoc>());
const selectedRegistration = ref();
const registrationWeek = ref("");

const spotsAvailable = ref(0);

const registrationSearchIsPending = ref(false);
const formSubmissionIsPending = ref(false);
const noResults = ref(false);

async function handleSubmit(): Promise<void> {
  formInputModel.value.forEach((field, index) => {
    formValidationRefs.value[index] = validateFilled(
      formInputModel.value,
      index
    );
  });

  let valid = formValidationRefs.value.every((i) => i === false);
  if (valid) {
    formSubmissionIsPending.value = true;

    try {
      let requestBody = {
        DepartureDate: selectedRegistration.value.departure,
        DurationInDays: selectedRegistration.value.durationInDays,
        Email: selectedRegistration.value.email,
        DocumentID: selectedRegistration.value.id,
        ExtraVehicleInfo: {
          Make: formInputModel.value[0],
          Model: formInputModel.value[1],
          LicensePlate: formInputModel.value[2],
        },
        AssociatedWeek: registrationWeek.value,
      };

      const res = await fetch(
        "https://app-y4uhxvobbq-uc.a.run.app/extra-vehicle-checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await res.json();

      window.location.href = data.checkoutSessionUrl;
    } catch (error) {
      console.error(error);
      toast("Something went wrong... please try again", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      } as ToastOptions);

      formSubmissionIsPending.value = false;
    }
  }
}

async function searchForRegistration() {
  selectedRegistration.value = {};
  userRegistrations.value = [];
  noResults.value = false;
  searchedEmailRef.value = emailRef.value;

  if (emailRef.value) {
    registrationSearchIsPending.value = true;

    try {
      const res = await fetch(
        "https://app-y4uhxvobbq-uc.a.run.app/get-registration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: emailRef.value }),
        }
      );
      const getRegistrationData = await res.json();
      const registrationResults = getRegistrationData.registrationResults;

      registrationResults.forEach((doc: any) => {
        if (
          !doc.ExtraVehicleInfo &&
          doc.ArrivalDate > dateToYyyyMmDdString(new Date())
        ) {
          formatRegistrationDisplayDate(doc.ArrivalDate);
          formatRegistrationDisplayDate(doc.DepartureDate);

          userRegistrations.value.push({
            id: doc.id,
            name: doc.Name,
            email: doc.Email,
            unit: doc.Unit,
            arrival: doc.ArrivalDate,
            departure: doc.DepartureDate,
            displayArrival: formatRegistrationDisplayDate(doc.ArrivalDate),
            displayDeparture: formatRegistrationDisplayDate(doc.DepartureDate),
            durationInDays: doc.DurationOfStay,
            arrivalTimestamp: doc.ArrivalTimestamp,
          } as UserDoc);
        }
      });

      userRegistrations.value.sort(
        (a, b) => a.arrivalTimestamp - b.arrivalTimestamp
      );
    } catch (error) {
      console.error(error);

      toast("Something went wrong... please try again", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      } as ToastOptions);
    }
  }
  registrationSearchIsPending.value = false;

  if (userRegistrations.value.length == 0) noResults.value = true;
}

async function updateSpotsAvailable(document: any) {
  let today = removeTimezoneOffset(document.arrival);
  let previousSaturday = getPreviousSaturday(new Date(new Date(today)));

  try {
    const res = await fetch(
      "https://app-y4uhxvobbq-uc.a.run.app/get-extra-vehicle-spots-available",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ associatedSaturday: previousSaturday }),
      }
    );
    const data = await res.json();

    registrationWeek.value = data.id;
    spotsAvailable.value = data.availableSpots;

    selectedRegistration.value = document;
  } catch (error) {
    console.error(error);

    toast("Something went wrong... please try again", {
      type: "error",
      position: toast.POSITION.TOP_CENTER,
    } as ToastOptions);
  }
}

function formatRegistrationDisplayDate(dateString: string): string {
  let formattedDisplayDate = `${dateString.slice(-5, -3)}/${dateString.slice(
    -2
  )}/${dateString.slice(0, 4)}`;

  return formattedDisplayDate;
}

function removeTimezoneOffset(date: string): Date {
  return new Date(
    new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[17] || (_cache[17] = _createStaticVNode("<h3 class=\"registration-header\"><div class=\"registration-header__divider-line\"></div> Extra Vehicle Policy <div class=\"registration-header__divider-line\"></div></h3><h4 class=\"registration-form__instructions\"> Parking is limited at Adagio. Each unit is allocated 2 parking spaces. There are a limited number of third car spaces available each week for purchase through the association at $100 per week. They are sold strictly on a first come, first served basis. </h4><h4 class=\"registration-form__instructions\"> To secure an additional parking pass, please enter your email, select your registration, and then complete the form below: </h4>", 3)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        class: "search-registration__form",
        onKeydown: _withKeys(searchForRegistration, ["enter"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          (!registrationSearchIsPending.value)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "search-registration__form-icon",
                src: _imports_0,
                alt: "search-icon",
                onClick: searchForRegistration
              }))
            : _createCommentVNode("", true),
          (registrationSearchIsPending.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[11] || (_cache[11] = [
                _createElementVNode("div", { class: "spinner-circle" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            class: "registration-form__input search-registration__form-input large-screen-placeholder",
            type: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((emailRef).value = $event)),
            name: "email",
            placeholder: "Enter your email to search for your registration...",
            required: ""
          }, null, 512), [
            [_vModelText, emailRef.value]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: "registration-form__input search-registration__form-input small-screen-placeholder",
            type: "email",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((emailRef).value = $event)),
            name: "email",
            placeholder: "Search via email...",
            required: ""
          }, null, 512), [
            [_vModelText, emailRef.value]
          ])
        ]),
        (
          selectedRegistration.value &&
          JSON.stringify(selectedRegistration.value) !== '{}' &&
          (spotsAvailable.value === 0 || !spotsAvailable.value)
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, " No additional parking is available for the selected registration. "))
          : (!noResults.value && userRegistrations.value.length !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Results for " + _toDisplayString(searchedEmailRef.value) + ": ", 1))
            : (noResults.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, " No results found... Please try a different email "))
              : _createCommentVNode("", true)
      ], 32),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userRegistrations.value, (document, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "document-radio",
          key: index,
          onInput: ($event: any) => (updateSpotsAvailable(document))
        }, [
          _createElementVNode("label", _hoisted_9, [
            _createElementVNode("input", {
              type: "radio",
              name: "document-selection-radio",
              value: document,
              id: `user-registration-${index}`
            }, null, 8, _hoisted_10),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "document-info__line-label" }, "Name:", -1)),
                _createElementVNode("span", _hoisted_13, _toDisplayString(document.name), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "document-info__line-label" }, "Unit Booked:", -1)),
                _createElementVNode("span", _hoisted_15, _toDisplayString(document.unit), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "document-info__line-label" }, "Arrival Date:", -1)),
                _createElementVNode("span", _hoisted_17, _toDisplayString(document.displayArrival), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "document-info__line-label" }, "Departure Date:", -1)),
                _createElementVNode("span", _hoisted_19, _toDisplayString(document.displayDeparture), 1)
              ])
            ])
          ])
        ], 40, _hoisted_8))
      }), 128))
    ]),
    (
      selectedRegistration.value &&
      JSON.stringify(selectedRegistration.value) !== '{}' &&
      spotsAvailable.value !== 0 &&
      spotsAvailable.value
    )
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "registration-form",
          onSubmit: _withModifiers(handleSubmit, ["prevent"]),
          novalidate: "true"
        }, [
          _createElementVNode("label", {
            class: _normalizeClass([{ visible: formValidationRefs.value[_unref(vehicleFieldEnum).MAKE] }, "registration-form__label--error"])
          }, "*Required", 2),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["registration-form__input", { 'check-validation': formValidationRefs.value[_unref(vehicleFieldEnum).MAKE] }]),
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formInputModel.value[_unref(vehicleFieldEnum).MAKE]) = $event)),
            name: "make",
            placeholder: "Make*",
            required: "",
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (
        formValidationRefs.value[_unref(vehicleFieldEnum).MAKE] = _unref(validateFilled)(
          formInputModel.value,
          _unref(vehicleFieldEnum).MAKE
        )
      )),
            onFocus: _cache[4] || (_cache[4] = ($event: any) => (formValidationRefs.value[_unref(vehicleFieldEnum).MAKE] = false))
          }, null, 34), [
            [_vModelText, formInputModel.value[_unref(vehicleFieldEnum).MAKE]]
          ]),
          _createElementVNode("label", {
            class: _normalizeClass([{ visible: formValidationRefs.value[_unref(vehicleFieldEnum).MODEL] }, "registration-form__label--error"])
          }, "*Required", 2),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["registration-form__input", {
        'check-validation': formValidationRefs.value[_unref(vehicleFieldEnum).MODEL],
      }]),
            type: "text",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formInputModel.value[_unref(vehicleFieldEnum).MODEL]) = $event)),
            name: "MODEL",
            placeholder: "Model*",
            required: "",
            onBlur: _cache[6] || (_cache[6] = ($event: any) => (
        formValidationRefs.value[_unref(vehicleFieldEnum).MODEL] = _unref(validateFilled)(
          formInputModel.value,
          _unref(vehicleFieldEnum).MODEL
        )
      )),
            onFocus: _cache[7] || (_cache[7] = ($event: any) => (formValidationRefs.value[_unref(vehicleFieldEnum).MODEL] = false))
          }, null, 34), [
            [_vModelText, formInputModel.value[_unref(vehicleFieldEnum).MODEL]]
          ]),
          _createElementVNode("label", {
            class: _normalizeClass([{ visible: formValidationRefs.value[_unref(vehicleFieldEnum).LICENSE_PLATE] }, "registration-form__label--error"])
          }, "*Required", 2),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["registration-form__input", {
        'check-validation': formValidationRefs.value[_unref(vehicleFieldEnum).LICENSE_PLATE],
      }]),
            type: "text",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formInputModel.value[_unref(vehicleFieldEnum).LICENSE_PLATE]) = $event)),
            name: "license-plate",
            maxlength: "8",
            placeholder: "License Plate*",
            required: "",
            onBlur: _cache[9] || (_cache[9] = ($event: any) => (
        formValidationRefs.value[_unref(vehicleFieldEnum).LICENSE_PLATE] = _unref(validateFilled)(
          formInputModel.value,
          _unref(vehicleFieldEnum).LICENSE_PLATE
        )
      )),
            onFocus: _cache[10] || (_cache[10] = ($event: any) => (formValidationRefs.value[_unref(vehicleFieldEnum).LICENSE_PLATE] = false))
          }, null, 34), [
            [_vModelText, formInputModel.value[_unref(vehicleFieldEnum).LICENSE_PLATE]]
          ]),
          _createElementVNode("button", _hoisted_20, [
            (!formSubmissionIsPending.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Submit Form"))
              : _createCommentVNode("", true),
            (formSubmissionIsPending.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[16] || (_cache[16] = [
                  _createElementVNode("div", { class: "submit spinner-circle" }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ], 32))
      : _createCommentVNode("", true)
  ], 64))
}
}

})