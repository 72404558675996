import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"
import _imports_0 from '../assets/success-icon.gif'
import _imports_1 from '../assets/failure-icon.gif'


const _hoisted_1 = { class: "checkout-results" }
const _hoisted_2 = {
  key: 0,
  class: "checkout-results--success"
}
const _hoisted_3 = {
  key: 1,
  class: "checkout-results--failure"
}


import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutResult',
  props: {
  success: {
    type: Boolean,
  },
},
  setup(__props) {

const router = useRouter();

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.success)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            class: "checkout-results__icon",
            src: _imports_0,
            alt: "success-icon"
          }, null, -1),
          _createElementVNode("div", { class: "checkout-results__text" }, " Checkout Success! Your registration details are being processed. ", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            class: "checkout-results__icon",
            src: _imports_1,
            alt: "failure-icon"
          }, null, -1),
          _createElementVNode("div", { class: "checkout-results__text" }, " Cart was canceled or checkout otherwise failed. Please try again. ", -1)
        ]))),
    _createElementVNode("button", {
      style: {"margin-top":"2rem"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
    }, " Return to Home ")
  ]))
}
}

})