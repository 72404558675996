<template>
  <h3 class="registration-header">
    <div class="registration-header__divider-line"></div>
    Extra Vehicle Policy
    <div class="registration-header__divider-line"></div>
  </h3>
  <h4 class="registration-form__instructions">
    Parking is limited at Adagio. Each unit is allocated 2 parking spaces. There
    are a limited number of third car spaces available each week for purchase
    through the association at $100 per week. They are sold strictly on a first
    come, first served basis.
  </h4>
  <h4 class="registration-form__instructions">
    To secure an additional parking pass, please enter your email, select your
    registration, and then complete the form below:
  </h4>

  <div class="email-search">
    <!-- EMAIL -->
    <form
      class="search-registration__form"
      @keydown.enter="searchForRegistration"
    >
      <div class="search-registration__icon-container">
        <img
          class="search-registration__form-icon"
          src="../assets/search-icon.svg"
          alt="search-icon"
          @click="searchForRegistration"
          v-if="!registrationSearchIsPending"
        />
        <div class="spinner" v-if="registrationSearchIsPending">
          <div class="spinner-circle"></div>
        </div>
      </div>
      <div class="search-registration__form-input-container">
        <input
          class="registration-form__input search-registration__form-input large-screen-placeholder"
          type="email"
          v-model="emailRef"
          name="email"
          placeholder="Enter your email to search for your registration..."
          required
        />
        <input
          class="registration-form__input search-registration__form-input small-screen-placeholder"
          type="email"
          v-model="emailRef"
          name="email"
          placeholder="Search via email..."
          required
        />
      </div>
      <div
        v-if="
          selectedRegistration &&
          JSON.stringify(selectedRegistration) !== '{}' &&
          (spotsAvailable === 0 || !spotsAvailable)
        "
        class="no-spots-available"
      >
        No additional parking is available for the selected registration.
      </div>
      <div
        v-else-if="!noResults && userRegistrations.length !== 0"
        class="search-registration_results-text"
      >
        Results for {{ searchedEmailRef }}:
      </div>
      <p class="search-registration__no-results" v-else-if="noResults">
        No results found... Please try a different email
      </p>
    </form>
    <div
      v-for="(document, index) in userRegistrations"
      class="document-radio"
      :key="index"
      @input="updateSpotsAvailable(document)"
    >
      <label class="document-radio__container">
        <input
          type="radio"
          name="document-selection-radio"
          :value="document"
          :id="`user-registration-${index}`"
        />
        <div class="document-info__container">
          <div class="document-info__line">
            <span class="document-info__line-label">Name:</span>
            <span class="document-info__line-content">
              {{ document.name }}</span
            >
          </div>
          <div class="document-info__line">
            <span class="document-info__line-label">Unit Booked:</span>
            <span class="document-info__line-content">
              {{ document.unit }}</span
            >
          </div>
          <div class="document-info__line">
            <span class="document-info__line-label">Arrival Date:</span>
            <span class="document-info__line-content">
              {{ document.displayArrival }}</span
            >
          </div>
          <div class="document-info__line">
            <span class="document-info__line-label">Departure Date:</span>
            <span class="document-info__line-content">
              {{ document.displayDeparture }}</span
            >
          </div>
        </div>
      </label>
    </div>
  </div>

  <form
    v-if="
      selectedRegistration &&
      JSON.stringify(selectedRegistration) !== '{}' &&
      spotsAvailable !== 0 &&
      spotsAvailable
    "
    class="registration-form"
    @submit.prevent="handleSubmit"
    novalidate="true"
  >
    <!-- MAKE -->
    <label
      :class="{ visible: formValidationRefs[vehicleFieldEnum.MAKE] }"
      class="registration-form__label--error"
      >*Required</label
    >
    <input
      class="registration-form__input"
      :class="{ 'check-validation': formValidationRefs[vehicleFieldEnum.MAKE] }"
      type="text"
      v-model="formInputModel[vehicleFieldEnum.MAKE]"
      name="make"
      placeholder="Make*"
      required
      @blur="
        formValidationRefs[vehicleFieldEnum.MAKE] = validateFilled(
          formInputModel,
          vehicleFieldEnum.MAKE
        )
      "
      @focus="formValidationRefs[vehicleFieldEnum.MAKE] = false"
    />

    <!-- MODEL -->
    <label
      :class="{ visible: formValidationRefs[vehicleFieldEnum.MODEL] }"
      class="registration-form__label--error"
      >*Required</label
    >
    <input
      class="registration-form__input"
      :class="{
        'check-validation': formValidationRefs[vehicleFieldEnum.MODEL],
      }"
      type="text"
      v-model="formInputModel[vehicleFieldEnum.MODEL]"
      name="MODEL"
      placeholder="Model*"
      required
      @blur="
        formValidationRefs[vehicleFieldEnum.MODEL] = validateFilled(
          formInputModel,
          vehicleFieldEnum.MODEL
        )
      "
      @focus="formValidationRefs[vehicleFieldEnum.MODEL] = false"
    />

    <!-- LICENSE_PLATE -->
    <label
      :class="{ visible: formValidationRefs[vehicleFieldEnum.LICENSE_PLATE] }"
      class="registration-form__label--error"
      >*Required</label
    >
    <input
      class="registration-form__input"
      :class="{
        'check-validation': formValidationRefs[vehicleFieldEnum.LICENSE_PLATE],
      }"
      type="text"
      v-model="formInputModel[vehicleFieldEnum.LICENSE_PLATE]"
      name="license-plate"
      maxlength="8"
      placeholder="License Plate*"
      required
      @blur="
        formValidationRefs[vehicleFieldEnum.LICENSE_PLATE] = validateFilled(
          formInputModel,
          vehicleFieldEnum.LICENSE_PLATE
        )
      "
      @focus="formValidationRefs[vehicleFieldEnum.LICENSE_PLATE] = false"
    />

    <!-- SUBMIT -->
    <button class="registration-form__button-submit">
      <span v-if="!formSubmissionIsPending">Submit Form</span>
      <div class="submit spinner" v-if="formSubmissionIsPending">
        <div class="submit spinner-circle"></div>
      </div>
    </button>
  </form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { vehicleFieldEnum } from "@/types/vehicleFieldEnum";
import { UserDoc } from "@/types/userDoc";
import {
  stringToDate,
  getNextSaturday,
  getPreviousSaturday,
  dateToYyyyMmDdString,
} from "@/helpers/dateHelpers";
import { validateFilled } from "@/helpers/validationHelpers";
import { toast, type ToastOptions } from "vue3-toastify";

const emailRef = ref("");
const searchedEmailRef = ref("");

const formInputModel = ref(["", "", ""]);
const formValidationRefs = ref([false, false, false, false]);

const userRegistrations = ref(Array<UserDoc>());
const selectedRegistration = ref();
const registrationWeek = ref("");

const spotsAvailable = ref(0);

const registrationSearchIsPending = ref(false);
const formSubmissionIsPending = ref(false);
const noResults = ref(false);

async function handleSubmit(): Promise<void> {
  formInputModel.value.forEach((field, index) => {
    formValidationRefs.value[index] = validateFilled(
      formInputModel.value,
      index
    );
  });

  let valid = formValidationRefs.value.every((i) => i === false);
  if (valid) {
    formSubmissionIsPending.value = true;

    try {
      let requestBody = {
        DepartureDate: selectedRegistration.value.departure,
        DurationInDays: selectedRegistration.value.durationInDays,
        Email: selectedRegistration.value.email,
        DocumentID: selectedRegistration.value.id,
        ExtraVehicleInfo: {
          Make: formInputModel.value[0],
          Model: formInputModel.value[1],
          LicensePlate: formInputModel.value[2],
        },
        AssociatedWeek: registrationWeek.value,
      };

      const res = await fetch(
        "https://app-y4uhxvobbq-uc.a.run.app/extra-vehicle-checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await res.json();

      window.location.href = data.checkoutSessionUrl;
    } catch (error) {
      console.error(error);
      toast("Something went wrong... please try again", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      } as ToastOptions);

      formSubmissionIsPending.value = false;
    }
  }
}

async function searchForRegistration() {
  selectedRegistration.value = {};
  userRegistrations.value = [];
  noResults.value = false;
  searchedEmailRef.value = emailRef.value;

  if (emailRef.value) {
    registrationSearchIsPending.value = true;

    try {
      const res = await fetch(
        "https://app-y4uhxvobbq-uc.a.run.app/get-registration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: emailRef.value }),
        }
      );
      const getRegistrationData = await res.json();
      const registrationResults = getRegistrationData.registrationResults;

      registrationResults.forEach((doc: any) => {
        if (
          !doc.ExtraVehicleInfo &&
          doc.ArrivalDate > dateToYyyyMmDdString(new Date())
        ) {
          formatRegistrationDisplayDate(doc.ArrivalDate);
          formatRegistrationDisplayDate(doc.DepartureDate);

          userRegistrations.value.push({
            id: doc.id,
            name: doc.Name,
            email: doc.Email,
            unit: doc.Unit,
            arrival: doc.ArrivalDate,
            departure: doc.DepartureDate,
            displayArrival: formatRegistrationDisplayDate(doc.ArrivalDate),
            displayDeparture: formatRegistrationDisplayDate(doc.DepartureDate),
            durationInDays: doc.DurationOfStay,
            arrivalTimestamp: doc.ArrivalTimestamp,
          } as UserDoc);
        }
      });

      userRegistrations.value.sort(
        (a, b) => a.arrivalTimestamp - b.arrivalTimestamp
      );
    } catch (error) {
      console.error(error);

      toast("Something went wrong... please try again", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
      } as ToastOptions);
    }
  }
  registrationSearchIsPending.value = false;

  if (userRegistrations.value.length == 0) noResults.value = true;
}

async function updateSpotsAvailable(document: any) {
  let today = removeTimezoneOffset(document.arrival);
  let previousSaturday = getPreviousSaturday(new Date(new Date(today)));

  try {
    const res = await fetch(
      "https://app-y4uhxvobbq-uc.a.run.app/get-extra-vehicle-spots-available",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ associatedSaturday: previousSaturday }),
      }
    );
    const data = await res.json();

    registrationWeek.value = data.id;
    spotsAvailable.value = data.availableSpots;

    selectedRegistration.value = document;
  } catch (error) {
    console.error(error);

    toast("Something went wrong... please try again", {
      type: "error",
      position: toast.POSITION.TOP_CENTER,
    } as ToastOptions);
  }
}

function formatRegistrationDisplayDate(dateString: string): string {
  let formattedDisplayDate = `${dateString.slice(-5, -3)}/${dateString.slice(
    -2
  )}/${dateString.slice(0, 4)}`;

  return formattedDisplayDate;
}

function removeTimezoneOffset(date: string): Date {
  return new Date(
    new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000
  );
}
</script>

<style>
.registration-header {
  margin: 0 auto 36px auto;
  width: clamp(60%, 60%, 80%);
  font-size: 1.5rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  align-items: center;
}

.registration-header__divider-line {
  height: 1px;
  background-color: rgb(198, 198, 198);
  content: "";
  width: 100%;
}

.registration-form__instructions {
  font-size: 18px;
  font-weight: 400;
  color: rgb(27, 27, 27);
  line-height: 1.25;
  max-width: 720px;
  margin: 0 auto 1.5rem;
}

.small-screen-placeholder {
  display: none;
}

.search-registration__icon-container {
  height: 1.75rem;
  width: 1.75rem;
  place-self: center center;
}

.search-registration__form-icon {
  height: 1.75rem;
}

.search-registration__form-icon:hover {
  cursor: pointer;
}

.search-registration__form {
  display: grid;
  grid-template-columns: 3rem 1fr;
  /* grid-template-rows: 1fr 1fr; */
  /* align-items: center; */
}

.search-registration__form-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.search-registration__no-results,
.no-spots-available,
.search-registration_results-text {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  margin-top: 1rem;
  grid-column: 2/3;
}

.search-registration__no-results,
.no-spots-available {
  color: rgb(230, 0, 0);
}

/* Small devices (portrait tablets and large MODELs, 600px-768px) */
@media only screen and (max-width: 768px) {
  .registration-form__instructions {
    margin: 0 1rem 1.5rem;
  }

  .email-search {
    margin: 0 1rem !important;
    text-wrap: wrap !important;
  }
}

@media only screen and (max-width: 530px) {
  .large-screen-placeholder {
    display: none;
  }

  .small-screen-placeholder {
    display: inline-block;
  }

  .search-registration__no-results,
  .no-spots-available,
  .search-registration_results-text {
    font-size: 1rem;
  }
}

.email-search {
  font-size: 18px;
  font-weight: 400;
  color: rgb(27, 27, 27);
  line-height: 1.25;
  max-width: 720px;
  margin: 0 auto;
}

.document-radio__container {
  display: flex;
  text-align: left;
  gap: 1rem;
}

.document-radio__container:hover {
  .document-info__container {
    background-color: rgb(221, 221, 221);
  }
}

.document-info__container {
  gap: 1rem;
  background-color: rgb(241, 241, 241);
  border-radius: 4px;
  margin: 1rem;
  width: 100%;
}

.document-info__line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem;
}

.spinner {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
}

.spinner-circle {
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 4px solid #888;
  border-color: rgb(27, 27, 27) rgb(27, 27, 27) transparent transparent;
  animation: spin 0.9s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submit.spinner-circle {
  border-color: rgb(241, 241, 241) rgb(241, 241, 241) transparent transparent;
}

.registration-form__button-submit {
  width: 14rem;
}
</style>
