import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/ChairsOnBeach.png'
import _imports_1 from '../assets/CleanLinens.png'
import _imports_2 from '../assets/HouseInHand.png'


const _hoisted_1 = { class: "home-view" }
const _hoisted_2 = { class: "grid-item-container" }
const _hoisted_3 = { class: "grid-item-container" }
const _hoisted_4 = { class: "grid-item-container" }

import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const router = useRouter();

const navigate = function (to: string) {
  router.push(to);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Chairs sitting on a beach",
        class: "grid-item-img"
      }, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "grid-item-header" }, "Registration by Renter", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (navigate('/registration-by-renter')))
      }, " Rental Guests ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("img", {
        src: _imports_1,
        alt: "Clean towels folded on neatly-made bed",
        class: "grid-item-img"
      }, null, -1)),
      _cache[6] || (_cache[6] = _createElementVNode("p", { class: "grid-item-header" }, "Registration by Owner", -1)),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (navigate('/registration-by-owner')))
      }, "Unit Owners")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_2,
        alt: "Small house in someone's palm",
        class: "grid-item-img"
      }, null, -1)),
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "grid-item-header" }, "Register Guests as PM", -1)),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (navigate('/register-guests-as-pm')))
      }, " Property Managers ")
    ])
  ]))
}
}

})