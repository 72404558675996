import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import FaqView from "@/views/FaqView.vue";
import RegistrationByRenter from "@/views/RegistrationByRenter.vue";
import RegistrationByOwner from "@/views/RegistrationByOwner.vue";
import RegistrationByPM from "@/views/RegistrationByPM.vue";
import RulesView from "@/views/RulesView.vue";
import ExtraVehicleParking from "@/views/ExtraVehicleParking.vue";
import CheckoutResult from "@/views/CheckoutResult.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/registration-by-renter",
    name: "registration-by-renter",
    component: RegistrationByRenter,
  },
  {
    path: "/registration-by-owner",
    name: "registration-by-owner",
    component: RegistrationByOwner,
  },
  {
    path: "/register-guests-as-pm",
    name: "register-guests-as-pm",
    component: RegistrationByPM,
  },
  {
    path: "/adagio-rules",
    name: "adagio-rules",
    component: RulesView,
  },
  {
    path: "/extra-vehicle-parking",
    name: "extra-vehicle-parking",
    component: ExtraVehicleParking,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
  },
  {
    path: "/checkout-success",
    name: "checkout-success",
    component: CheckoutResult,
    props: { success: true },
  },
  {
    path: "/checkout-canceled",
    name: "checkout-canceled",
    component: CheckoutResult,
    props: { success: false },
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(""),
  routes,
});

export default router;
